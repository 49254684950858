import { ApiService } from "@/core/shared/services/ApiService";
import type {
  LiveNotificationResponse,
  LiveNotificationUpdated,
} from "@/modules/notification/services/LiveNotificationService.types";

/**
 * API Service for the notifications
 * @class LiveNotificationService
 */
export default class LiveNotificationService {
  /**
   * Search all notifications
   * @endpoint /company/app-notifications
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static search(page: number, rows: number): Promise<LiveNotificationResponse> {
    return ApiService.get(`/company/app-notifications/${page}/${rows}`);
  }

  /**
   * Delete Notifications
   * @throws {ApiServiceError}
   * @return {Promise<LiveNotificationUpdated>}
   */
  static deleteAll(): Promise<LiveNotificationUpdated> {
    return ApiService.delete("/company/app-notifications/delete-all");
  }

  /**
   * ReadAll Notifications
   * @throws {ApiServiceError}
   * @return {Promise<LiveNotificationUpdated>}
   */
  static markReadAll(): Promise<LiveNotificationUpdated> {
    return ApiService.put("/company/app-notifications/read-all");
  }

  /**
   * Read Notification
   * @throws {ApiServiceError}
   * @return {Promise<LiveNotificationUpdated>}
   */
  static markRead(id: number): Promise<LiveNotificationUpdated> {
    return ApiService.put(`/company/app-notifications/${id}/read`);
  }

  /**
   * Delete Notification
   * @throws {ApiServiceError}
   * @return {Promise<LiveNotificationUpdated>}
   */
  static delete(id: number): Promise<LiveNotificationUpdated> {
    return ApiService.delete(`/company/app-notifications/${id}`);
  }
}
