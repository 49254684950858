import * as Sentry from "@sentry/vue";
import { injectable } from "tsyringe";
import { DsCookieAuthService } from "@devsalsa/vue-core";

@injectable()
export default class TheAuthService extends DsCookieAuthService {
  logout(): void {
    super.logout();
    //Clear the user from Sentry.
    if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
      Sentry.getCurrentScope().setUser(null);
    }
  }
}
