import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";
import CampaignCreationStepGuard from "@/modules/campaign/creation/helpers/Step/CampaignCreationStepGuard";

export default [
  {
    path: "/campaign",
    beforeEnter: [
      CampaignCreationStepGuard.creation,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "",
        redirect: { name: "Dashboard" },
      },
      {
        path: "recurring",
        name: "RecurringCampaignCreation",
        component: () =>
          import(
            "@/modules/campaign/creation/views/RecurringCampaignCreation.vue"
          ),
        meta: {
          title: "modules.campaign.details.meta",
        },
      },
      {
        path: "recurring-brief",
        name: "RecurringCampaignBrief",
        component: () =>
          import(
            "@/modules/campaign/creation/views/RecurringCampaignBrief.vue"
          ),
        meta: {
          title: "modules.campaign.details.meta",
        },
      },
      {
        path: "recurring-payment",
        name: "RecurringCampaignPayment",
        component: () =>
          import(
            "@/modules/campaign/creation/views/RecurringCampaignPayment.vue"
          ),
        meta: {
          title: "modules.campaign.details.meta",
        },
      },
      {
        path: "recurring-cancel",
        name: "RecurringCampaignCancellation",
        component: () =>
          import(
            "@/modules/campaign/creation/views/RecurringCampaignCancellation.vue"
          ),
        meta: { title: "modules.campaign.cancel.meta" },
      },
      {
        path: "basics/:brandId(\\d+)?/:productId(\\d+)?",
        name: "CampaignCreationBasics",
        component: () =>
          import(
            "@/modules/campaign/creation/views/CampaignCreationBasics.vue"
          ),
        meta: {
          title: "modules.campaign.details.meta",
          stepperPosition: 0,
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
      {
        path: "settings",
        name: "CampaignCreationSettings",
        component: () =>
          import(
            "@/modules/campaign/creation/views/CampaignCreationSettings.vue"
          ),
        beforeEnter: CampaignCreationStepGuard.basics,
        meta: {
          title: "modules.campaign.productInformation.meta",
          stepperPosition: 1,
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
      {
        path: "creators",
        name: "CampaignCreationCreators",
        component: () =>
          import(
            "@/modules/campaign/creation/views/CampaignCreationCreators.vue"
          ),
        beforeEnter: CampaignCreationStepGuard.settings,
        meta: {
          title: "modules.campaign.settings.meta",
          stepperPosition: 2,
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
      {
        path: "summary",
        name: "CampaignCreationSummary",
        component: () =>
          import(
            "@/modules/campaign/creation/views/CampaignCreationSummary.vue"
          ),
        beforeEnter: CampaignCreationStepGuard.summary,
        meta: {
          title: "modules.campaign.summary.meta",
          stepperPosition: 3,
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
      {
        path: "created",
        name: "CampaignCreated",
        component: () =>
          import("@/modules/campaign/creation/views/CampaignCreated.vue"),
        beforeEnter: CampaignCreationStepGuard.all,
        meta: {
          title: "modules.campaign.created.meta",
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
      {
        path: "cancel",
        name: "CampaignCancellation",
        component: () =>
          import("@/modules/campaign/creation/views/CampaignCancellation.vue"),
        meta: {
          title: "modules.campaign.cancel.meta",
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
    ],
  },
];
