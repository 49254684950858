import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";
import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";

export default [
  {
    path: "",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "/contracts",
        name: "Contracts",
        component: () => import("@/modules/contracts/views/TheContracts.vue"),
        meta: {
          title: "modules.contracts.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.RecurringCampaigns,
        },
      },
      {
        path: "/job/recurring/:contractId(\\d+)",
        name: "ContractSummary",
        component: () =>
          import("@/modules/contracts/views/ContractSummary.vue"),
        meta: {
          title: "modules.contracts.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.RecurringCampaigns,
        },
      },
      {
        path: "/job/recurring/:contractId(\\d+)/calendar",
        name: "ContractCalendar",
        component: () =>
          import("@/modules/contracts/views/ContractCalendar.vue"),
        meta: {
          title: "modules.contracts.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.RecurringCampaigns,
        },
      },
      {
        path: "/job/recurring/:contractId(\\d+)/content",
        name: "ContractContent",
        component: () =>
          import("@/modules/contracts/views/ContractContent.vue"),
        meta: {
          title: "modules.contracts.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.RecurringCampaigns,
        },
      },
    ],
  },
];
