import { MarketplaceCurrency } from "@/core/shared/enums/MarketplaceCurrencyEnum";
import AssetsService from "@/shared/services/AssetsService";

export class MarketplaceHandler {
  static readonly Host = window.location.hostname;
  static readonly USMarketplace = "us";
  static readonly CAMarketplace = "ca";
  static readonly UKMarketplace = "uk";
  static readonly AUMarketplace = "au";
  static readonly appDomain = import.meta.env.VITE_APP_DOMAIN;

  static getCurrent(): string {
    if (MarketplaceHandler.isUSMarketplace()) {
      return MarketplaceHandler.USMarketplace;
    }
    if (MarketplaceHandler.isCAMarketplace()) {
      return MarketplaceHandler.CAMarketplace;
    }
    if (MarketplaceHandler.isUKMarketplace()) {
      return MarketplaceHandler.UKMarketplace;
    }
    if (MarketplaceHandler.isAUMarketplace()) {
      return MarketplaceHandler.AUMarketplace;
    }

    return (window.location.href = `https://${MarketplaceHandler.USMarketplace}.${MarketplaceHandler.appDomain}`);
  }

  static isMarketplaceSelected(marketplace: string): boolean {
    return MarketplaceHandler.Host.includes(`${marketplace}.brand`);
  }

  static getCurrency(): string {
    if (MarketplaceHandler.isUSMarketplace()) {
      return MarketplaceCurrency.USCurrency;
    }
    if (MarketplaceHandler.isUKMarketplace()) {
      return MarketplaceCurrency.UKCurrency;
    }
    if (MarketplaceHandler.isCAMarketplace()) {
      return MarketplaceCurrency.CACurrency;
    }
    if (MarketplaceHandler.isAUMarketplace()) {
      return MarketplaceCurrency.AUCurrency;
    }
    return MarketplaceCurrency.USCurrency;
  }

  static getLocale(): string {
    if (MarketplaceHandler.isUKMarketplace()) {
      return "en-GB";
    }
    if (MarketplaceHandler.isCAMarketplace()) {
      return "en-CA";
    }
    if (MarketplaceHandler.isAUMarketplace()) {
      return "en-AU";
    }
    return "en-US";
  }

  private static isUSMarketplace() {
    return MarketplaceHandler.Host.includes("us.brand");
  }

  private static isCAMarketplace() {
    return MarketplaceHandler.Host.includes("ca.brand");
  }

  private static isUKMarketplace() {
    return MarketplaceHandler.Host.includes("uk.brand");
  }

  private static isAUMarketplace() {
    return MarketplaceHandler.Host.includes("au.brand");
  }

  static async getCurrentId() {
    try {
      const marketplaces = await AssetsService.getMarketplaces();
      const marketplace = marketplaces.find(
        (marketplace) =>
          marketplace.short_code === MarketplaceHandler.getCurrent()
      );
      if (marketplace) {
        return marketplace.id;
      }
      return 1;
    } catch (error) {
      return 1;
    }
  }
}
