import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import CampaignCreationStepValidator from "@/modules/campaign/creation/helpers/Step/CampaignCreationStepValidator";
import CampaignCreationLocalData from "@/modules/campaign/creation/helpers/CampaignCreationLocalData";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default class CampaignCreationStepGuard {
  /**
   *
   * @param to
   * @param from
   * @param next
   */
  static async creation(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    to.meta.prevRoute = from;
    return await OnboardingGuard.withCompleteOnboarding(to, from, next);
  }

  static async basics(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidBasics = CampaignCreationStepValidator.basics();

    if (!isValidBasics) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationBasics",
          true
        ),
      });
    }

    return next();
  }

  static async settings(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidBasics = CampaignCreationStepValidator.basics();
    if (!isValidBasics) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationBasics",
          true
        ),
      });
    }

    const isValidSettings = await CampaignCreationStepValidator.settings();
    if (!isValidSettings) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationSettings",
          true
        ),
      });
    }

    const isBundle = CampaignCreationStepValidator.isBundle();
    if (isBundle) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationSettings",
          true
        ),
      });
    }

    return next();
  }

  static async summary(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidBasics = CampaignCreationStepValidator.basics();
    if (!isValidBasics) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationBasics",
          true
        ),
      });
    }

    const isValidSettings = await CampaignCreationStepValidator.settings();
    if (!isValidSettings) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationSettings",
          true
        ),
      });
    }

    const isValidSummary = CampaignCreationStepValidator.summary();
    if (isValidSummary) {
      return next({
        name: CampaignCreationLocalData.setPosition(isValidSummary, true),
      });
    }

    return next();
  }

  static async all(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidProduct = CampaignCreationStepValidator.basics();
    if (!isValidProduct) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationBasics",
          true
        ),
      });
    }

    const isValidSettings = await CampaignCreationStepValidator.settings();
    if (!isValidSettings) {
      return next({
        name: CampaignCreationLocalData.setPosition(
          "CampaignCreationSettings",
          true
        ),
      });
    }

    const isValidSummary = CampaignCreationStepValidator.summary();
    if (isValidSummary) {
      return next({
        name: CampaignCreationLocalData.setPosition(isValidSummary, true),
      });
    }

    return next();
  }
}
