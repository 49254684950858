<template>
  <aside
    class="xl:flex flex-col w-full xl:w-[264px] h-full xl:p-6 flex-shrink-0 z-0 xl:sticky top-0 xl:max-h-[calc(100vh-64px)] xl:h-auto"
  >
    <div class="flex flex-col flex-grow overflow-y-auto">
      <div class="flex flex-col pb-6 mb-6 border-gray-200 border-b">
        <div class="text-lg font-semibold text-gray-900 truncate">
          {{ fullName }}
        </div>
        <div class="text-sm text-gray-500">
          {{
            $t("modules.settings.sidebarSettings.memberSince", {
              signUpDate: signUpDate,
            })
          }}
        </div>
      </div>
      <div class="flex-grow flex flex-col">
        <nav class="flex-1 space-y-4">
          <SidebarSettingsItem
            :to="{ name: 'Account' }"
            route-name="Account"
            icon="DsUserSolidIcon"
            :title="$t('modules.settings.account.sidebarText')"
          />
          <SidebarSettingsItem
            v-permission="[
              { scope: 'my_subscription', type: ['full', 'view'] },
            ]"
            :to="{ name: 'Subscription' }"
            route-name="Subscription"
            icon="DsGraphSolidIcon"
            :title="$t('modules.settings.subscription.sidebarText')"
          />
          <SidebarSettingsItem
            v-permission="[{ scope: 'wallet', type: ['full', 'view'] }]"
            :to="{ name: 'Wallet' }"
            route-name="Wallet"
            icon="DsWalletSolidIcon"
            :title="$t('modules.settings.wallet.sidebarText')"
          />
          <SidebarSettingsItem
            v-permission="[{ scope: 'billing', type: ['full', 'view'] }]"
            :to="{ name: 'Billing' }"
            route-name="Billing"
            icon="DsMoneySolidIcon"
            :title="$t('modules.settings.billing.sidebarText')"
          />
          <SidebarSettingsItem
            v-permission="[
              { scope: 'payment_methods', type: ['full', 'view'] },
            ]"
            :to="{ name: 'PaymentMethods' }"
            route-name="PaymentMethods"
            icon="DsCardSolidIcon"
            :title="$t('modules.settings.paymentMethods.sidebarText')"
          />
          <SidebarSettingsItem
            v-permission="[{ scope: 'brands', type: ['full', 'view'] }]"
            :to="{ name: 'Brands' }"
            route-name="Brands"
            icon="DsBriefcaseSolidIcon"
            :title="$t('modules.settings.brands.sidebarText')"
          />
          <SidebarSettingsItem
            v-permission="[{ scope: 'company_collaborators', type: ['full'] }]"
            :to="{ name: 'Collaborators' }"
            route-name="Collaborators"
            icon="DsUsersSolidIcon"
            :title="$t('modules.settings.collaborators.sidebarText')"
            icon-class="text-gray-400"
          />
          <SidebarSettingsItem
            v-permission="[{ scope: 'white_labeling', type: ['full', 'view'] }]"
            :to="{ name: 'WhiteLabeling' }"
            route-name="WhiteLabeling"
            icon="DsColorSwatchIcon"
            :title="$t('modules.settings.whiteLabeling.sidebarText')"
            icon-class="text-gray-400"
          />
          <SidebarSettingsItem
            :to="{ name: 'NotificationSettings' }"
            route-name="NotificationSettings"
            icon="DsBellSolidIcon"
            :title="$t('modules.settings.notifications.sidebarText')"
          />
          <DsButton
            theme-display="link"
            class="ds-link-no-underline hover:no-underline focus:no-underline p-1 w-full text-left"
            @click="onOpenContactModal"
            data-testid="contact-support-sidebar-link"
          >
            <DsContactSolidIcon
              class="h-5 w-5 inline-block mr-4 text-gray-400"
            />
            <span class="text-sm font-medium text-gray-500">
              {{ $t(`modules.settings.contact.sidebarText`) }}
            </span>
          </DsButton>
          <SidebarSettingsItem
            v-permission="[
              { scope: 'affiliate_program', type: ['full', 'view'] },
            ]"
            :to="{ name: 'AffiliateProgram' }"
            route-name="AffiliateProgram"
            icon="DsTicketSolidIcon"
            :title="$t('modules.settings.affiliate.sidebarText')"
          />
        </nav>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { computed } from "vue";

import {
  DsButton,
  DsContactSolidIcon,
  DsModalHandler,
} from "@devsalsa/vue-core";

import SidebarSettingsItem from "@/modules/settings/components/SidebarSettingsItem.vue";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import Datetime from "@/core/shared/helpers/Datetime";

defineOptions({
  name: "SidebarSettings",
});

const fullName = computed((): string => {
  return `${AccountHandler.accountInfo.value?.first_name || ""} ${
    AccountHandler.accountInfo.value?.last_name || ""
  }`;
});
const signUpDate = computed((): string => {
  return Datetime.formatUnixTime(
    AccountHandler.accountInfo.value?.date_created || 0,
    "short"
  );
});

function onOpenContactModal(): void {
  DsModalHandler.open("contact-support-modal");
}
</script>
