import { ApiService } from "@/core/shared/services/ApiService";
import type {
  ProductUpdated,
  ProductId,
  ProductStats,
  ProductExtended,
} from "@/modules/product/services/ProductService.types";
import type { AxiosProgressEvent } from "axios";

export default class ProductService {
  static update(
    id: number,
    productData: FormData,
    onUploadProgress?: (event: AxiosProgressEvent) => void
  ): Promise<ProductExtended> {
    return ApiService.post(
      `/company/products/${id}`,
      productData,
      undefined,
      undefined,
      onUploadProgress
    );
  }

  static get(id: number): Promise<ProductExtended> {
    return ApiService.get(`/company/products/${id}`);
  }

  static create(
    productData: FormData,
    onUploadProgress?: (event: AxiosProgressEvent) => void
  ): Promise<ProductExtended> {
    return ApiService.post(
      "/company/products",
      productData,
      undefined,
      undefined,
      onUploadProgress
    );
  }

  static stats(id: number): Promise<ProductStats> {
    return ApiService.get(`/company/products/${id}/stats`);
  }

  static delete(id: number): Promise<ProductUpdated> {
    return ApiService.delete(`/company/products/${id}`);
  }

  static duplicate(id: number): Promise<ProductId> {
    return ApiService.post(`/company/products/${id}/clone`);
  }
}
