import type { Subscription } from "@/modules/settings/services/subscription/SubscriptionService.types";
import type { WatermarkStatus } from "@/modules/settings/enums/WatermarkStatusEnum";
import type { CollaboratorSettings } from "@/modules/settings/services/collaborators/CollaboratorsService.types";

/**
 * @interface AccountToken
 * @memberOf AccountService
 * @property token {string} Token
 */
export interface AccountToken {
  token: string;
}

/**
 * @interface RegisterFormData
 * @memberOf AccountService
 * @property email {string} Email
 * @property password {string} Password
 */
export interface RegisterFormData {
  email: string;
  password: string;
}

/**
 * @interface LoginFormData
 * @memberOf AccountService
 * @property email {string} Email
 * @property password {string} Password
 */
export interface LoginFormData {
  email: string;
  password: string;
}

/**
 * @interface LoginFormData
 * @memberOf AccountService
 * @property email {string} Email
 * @property password {string} Password
 */
export interface TwoFactorAuthenticationFormData {
  code: string;
}

/**
 * @interface UpdateEmailFormData
 * @memberOf AccountService
 * @property email {string} Email
 * @property password {string} Password
 */
export interface UpdateEmailFormData {
  email: string;
  password: string;
}

/**
 * @interface EmailFormData
 * @memberOf AccountService
 * @property email {string} Email
 */
export interface EmailFormData {
  email: string;
}

/**
 * @interface PasswordFormData
 * @memberOf AccountService
 * @property password {string} Password
 */
export interface PasswordFormData {
  password: string;
}

/**
 * @interface UpdatePasswordFormData
 * @memberOf AccountService
 * @property old_password {string} Old Password
 * @property new_password {string} New Password
 */
export interface UpdatePasswordFormData {
  old_password: string;
  new_password: string;
}

/**
 * @interface ProfileInfo
 * @memberOf AccountService
 * @property first_name {string | null} First name
 * @property last_name {string | null} Last name
 * @property phone {string | null} Phone number
 * @property linked_in {string | null} LinkedIn url
 */
export interface ProfileInfo {
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  country_code: string | null;
  linked_in: string | null;
}

export enum KycStatus {
  Na = "NA",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

/**
 * @interface AccountInfo
 * @memberOf AccountService
 * @property email_verified {number} Email verified (1 -> Yes, 0 -> No)
 * @property email {string} email
 * @property date_created {bigint} Date created
 * @property date_updated {bigint} Date updated
 */
export interface AccountInfo extends ProfileInfo {
  id: number;
  email_verified: number;
  email: string;
  date_created: number;
  date_updated: number;
  onboard_position: number;
  wallet_balance: number;
  admin_id?: number;
  referred_by: number;
  referral_code: number;
  wire_transfer_code: string;
  paypal_email: string;
  is_content_privacy: boolean;
  kyc_status: KycStatus;
  suspended: boolean;
  stripe_subscription: Subscription;
  stripe_pay_as_you_go_fee_override: number;
  watermark_status: WatermarkStatus;
  whitelabel_status: number;
  is_disable_2fa: boolean;
  collaborator: CollaboratorSettings;
  is_collaborator: boolean;
  default_marketplace: Marketplace;
  intercom_key_hashed: string;
}

/**
 * @interface AccountUpdated
 * @memberOf AccountService
 * @property updated {number} Updated (1 -> Yes, 0 -> No)
 */
export interface AccountUpdated {
  updated: number;
}

export interface AccountTwoFactorAuthenticationCodeSent {
  success: number;
  seconds: number;
}

export interface ContactResponse {
  updated: number;
}

export interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export type ProfileInformationFormData = {
  first_name: string;
  last_name: string;
  phone: string;
  country_code: string;
  linked_in: string;
};

export type Marketplace = {
  currency: MarketplaceCurrency;
  enabled: boolean;
  host: string;
  id: 1;
  language: MarketplaceLanguage;
  name: string;
  short_code: string;
};

export type MarketplaceCurrency = {
  currency_code: string;
  enabled: boolean;
  id: number;
  name: string;
  symbol: string;
};

export type MarketplaceLanguage = {
  code: string;
  id: number;
  name: string;
};

export interface VerificationMethods {
  email: string;
  phone_numbers: VerificationPhone[];
}

export interface VerificationPhone {
  id: number;
  value: string;
  is_primary: boolean;
}

export interface Phone {
  company_id: number;
  phone_number: number;
  is_primary: false;
  status: string;
  codes_2fa_sent: number;
  date_last_2fa_code_sent: number;
  can_retry_in_seconds: number;
}

export interface PhoneUpdated {
  updated: number;
}
