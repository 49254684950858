import type { SubmissionContext } from "vee-validate";
import type { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

export interface FormHandlerData {
  loading: boolean;
  populating?: boolean;
  // model can be used to manage all the values of a form
  // eslint-disable-next-line
  model?: any;
  // validationSchema should be a yup object.
  // eslint-disable-next-line
  validationSchema?: any;
  // extra can be used for any extra data that you need
  // eslint-disable-next-line
  extra?: any;
}

export class FormHandler {
  static setErrors(
    error: BadRequestApiServiceError,
    actions: SubmissionContext,
    fields: string[] = [],
    scroll = true,
    focus = true
  ): void {
    //Let's focus on a specific field if the API provided the information.
    if (Object.keys(error.getValidationFields()).length > 0) {
      let focused = false;
      let scrolled = false;
      for (const fieldName in error.getValidationFields()) {
        if (fields.length === 0 || fields.includes(fieldName)) {
          for (const ruleName in error.getValidationFieldRules(fieldName)) {
            const rule = error.getValidationFieldRule(fieldName, ruleName);
            actions.setFieldError(
              fieldName,
              error.getTranslatedValidationRule(ruleName, rule)
            );
            if (scroll && !scrolled) {
              FormHandler.scrollField(fieldName);
              scrolled = true;
            }
            if (focus && !focused) {
              FormHandler.focusField(fieldName, scrolled);
              focused = true;
            }
          }
        }
      }
    } else if (actions.evt?.target) {
      //Otherwise, we focus the flash message located just before the form.
      scroll && FormHandler.scrollFlash(actions.evt.target as HTMLElement);
    }
  }

  static scrollFlash(form: HTMLElement) {
    form.previousElementSibling?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }

  static scrollField(fieldName: string) {
    document.getElementsByName(fieldName)[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }

  static focusField(fieldName: string, preventScroll = false) {
    const field = document.getElementsByName(fieldName)[0] as HTMLElement;

    //TODO Improve scroll when migrated
    if (field.getAttribute("type") !== "hidden") {
      field.focus({ preventScroll: preventScroll });
    } else {
      document.getElementById(fieldName)?.scrollIntoView();
    }
  }
}
