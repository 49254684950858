import { createI18n } from "vue-i18n";
import type { IntlDateTimeFormats, IntlNumberFormats } from "vue-i18n";

import enUs from "@/locales/en-US.json";
import enGb from "@/locales/en-GB.json";
import enAu from "@/locales/en-AU.json";
import enCa from "@/locales/en-CA.json";
import enCore from "@devsalsa/vue-core/dist/locales/en.json";
import { MarketplaceHandler } from "@/core/shared/helpers/MarketplaceHandler";

const datetimeFormats: IntlDateTimeFormats = {
  "en-US": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    numericZeros: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    monthWithDay: {
      month: "long",
      day: "numeric",
    },
    monthDayYear: {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
    monthYear: {
      month: "long",
      year: "numeric",
    },
  },
  "en-GB": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    numericZeros: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    monthWithDay: {
      month: "long",
      day: "numeric",
    },
    monthDayYear: {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
    monthYear: {
      month: "long",
      year: "numeric",
    },
  },
  "en-AU": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    numericZeros: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    monthWithDay: {
      month: "long",
      day: "numeric",
    },
    monthDayYear: {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
    monthYear: {
      month: "long",
      year: "numeric",
    },
  },
  "en-CA": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    numeric: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    numericZeros: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    },
    monthWithDay: {
      month: "long",
      day: "numeric",
    },
    monthDayYear: {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
    monthYear: {
      month: "long",
      year: "numeric",
    },
  },
};

const numberFormats: IntlNumberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-CA": {
    currency: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-GB": {
    currency: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-AU": {
    currency: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: MarketplaceHandler.getLocale(),
  fallbackLocale: "en-US",
  messages: {
    "en-US": { ...enUs, ...enCore },
    "en-GB": { ...enGb, ...enCore },
    "en-CA": { ...enCa, ...enCore },
    "en-AU": { ...enAu, ...enCore },
  },
  datetimeFormats,
  numberFormats,
});
