import type { App } from "vue";
import { DsCurrency } from "@devsalsa/vue-core";
import { MarketplaceHandler } from "@/core/shared/helpers/MarketplaceHandler";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    /**
     * @remarks
     * The locale currency of localization.
     *@methods format(cents: number)
     *@methods fromCents(cents: number)
     *@methods toCents(amount: number)
     */
    $currency: typeof DsCurrency;
    $formatCurrency: (value: number) => string;
  }
}

export default {
  install(app: App) {
    // configure the app
    const currency = MarketplaceHandler.getCurrency();
    app.config.globalProperties.$currency = DsCurrency;
    app.config.globalProperties.$formatCurrency = (value: number): string => {
      return new Intl.NumberFormat("en", {
        style: "currency",
        currency: currency,
        currencyDisplay: "narrowSymbol",
      }).format(value);
    };
  },
};
