import SubscriptionHandler from "@/shared/helpers/SubscriptionHandler";
import type { RouteLocationNormalized } from "vue-router";
import SubscriptionService from "@/modules/settings/services/subscription/SubscriptionService";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import type { NavigationGuardNext } from "vue-router";
import { DsFlashNotifier } from "@devsalsa/vue-core";
import i18n from "@/i18n";

export default class SubscriptionGuard {
  static async subscribed(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (to.query.session_id) {
      await SubscriptionService.synchronizeSession();
      await AccountHandler.getInfo(true);
    }
    DsFlashNotifier.info(
      i18n.global.t(
        "modules.settings.subscription.currentSubscription.plan.flash.subscribed"
      )
    );
    return next({ name: "Subscription" });
  }

  static async paymentUpdated(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    await SubscriptionService.synchronizeData();
    await AccountHandler.getInfo(true);
    DsFlashNotifier.info(
      i18n.global.t(
        "modules.settings.subscription.currentSubscription.plan.flash.paymentUpdated"
      )
    );
    return next({ name: "Subscription" });
  }

  static async withSubscription(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const { stripe_subscription } = await AccountHandler.getInfo();

    const invalidSubscription =
      SubscriptionHandler.hasInvalidSubscription(stripe_subscription);

    if (invalidSubscription) {
      return next({
        name: "SubscriptionPlans",
      });
    }

    if (to.query.update) {
      await SubscriptionService.synchronizeData();
      await AccountHandler.getInfo(true);
      return next({ name: "Subscription" });
    }
    next();
  }
}
