import { ApiService } from "@/core/shared/services/ApiService";
import type {
  BrandsInfo,
  CompanyCreatorBlocked,
  ContentPrivacyUpdated,
  CompanyStats,
} from "@/shared/services/CompanyService.types";
import type {
  TodoResponse,
  TodoResolved,
} from "@/shared/services/CompanyService.types";

export default class CompanyService {
  /**
   * Get brands of the company
   * @returns brands
   */
  static getBrands(): Promise<BrandsInfo> {
    return ApiService.get("/company/companies/brands");
  }

  static stats(): Promise<CompanyStats> {
    return ApiService.get("/company/companies/stats");
  }

  static todos(page = 0, rows = 5): Promise<TodoResponse> {
    return ApiService.get(`/company/companies/todos/${page}/${rows}`);
  }

  static resolveTodo(
    todo_id: number,
    todo_class: string
  ): Promise<TodoResolved> {
    return ApiService.put(`/company/companies/todos/${todo_id}/resolve`, {
      todo_class,
    });
  }

  static blockCreator(creator_id: number): Promise<CompanyCreatorBlocked> {
    return ApiService.post(
      `/company/companies/blacklisted-creators/${creator_id}/add`
    );
  }

  static unblockCreator(creator_id: number): Promise<CompanyCreatorBlocked> {
    return ApiService.delete(
      `/company/companies/blacklisted-creators/${creator_id}/remove`
    );
  }

  static setContentPrivacy(
    content_privacy: number
  ): Promise<ContentPrivacyUpdated> {
    return ApiService.put(`/company/companies/set-content-privacy`, {
      content_privacy,
    });
  }
}
