<template>
  <transition name="fade" mode="out-in">
    <component :is="layout" v-if="layout" />
  </transition>
  <DsNavigationLoader />
  <DsToastNotifier />
  <DsLiveNotifier v-if="showLiveNotifierOnlyOnDesktop" />
</template>

<script setup lang="ts">
import {
  computed,
  markRaw,
  onMounted,
  onUnmounted,
  shallowRef,
  watch,
} from "vue";
import PageNotFoundLayout from "@/shared/modules/page-not-found/components/PageNotFoundLayout.vue";
import type { Presence } from "@/store/ChatStore";
import { useChatStore } from "@/store/ChatStore";
import { useWebSocketStore } from "@/store/WebSocketStore";
import { useDebounceFn } from "@vueuse/core";
import { useRoute } from "vue-router";

import {
  DsLiveNotifier,
  DsNavigationLoader,
  DsNotifier,
  DsToastNotifier,
  useDsSystemNotifierStore,
} from "@devsalsa/vue-core";

import AccountLayout from "@/core/modules/account/components/AccountLayout.vue";
import AuthenticatedLayout from "@/core/shared/components/Layout/AuthenticatedLayout.vue";
import WebSocketReconnectionErrorToast from "@/core/shared/plugins/WebSocket/WebSocketReconnectionErrorToast.vue";

import OnboardingLayout from "@/modules/onboarding/components/OnboardingLayout.vue";
import SettingsLayout from "@/modules/settings/components/SettingsLayout.vue";

import AccountService from "@/core/modules/account/services/AccountService";
import { WebSocketService } from "@/core/shared/services/WebSocket/WebSocketService";
import PresenceService from "@/shared/services/PresenceService";

import { AuthTokenWatcher } from "@/core/shared/helpers/Auth/AuthTokenWatcher";
import Browser from "@/core/shared/helpers/Browser";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

const route = useRoute();

let intervalId = 0;
let unsubscribePresences = () => {
  return;
};
let debouncePresences = () => {
  return;
};
let unsubscribeSocketAuthenticated = () => {
  return;
};
const layouts = {
  SettingsLayout,
  OnboardingLayout,
  AccountLayout,
  PageNotFoundLayout,
};

const layout = shallowRef();

const showLiveNotifierOnlyOnDesktop = computed((): boolean => {
  return !Browser.isMobile();
});

onMounted(() => {
  AuthTokenWatcher.start();
  debouncePresences = useDebounceFn(() => {
    pollPresence();
  }, 500);

  unsubscribePresences = useChatStore().$onAction(({ name, after }) => {
    after(() => {
      if (name === "addRegisterPresence") {
        debouncePresences();
      }
    });
  });

  unsubscribeSocketAuthenticated = useWebSocketStore().$onAction(
    ({ name, after }) => {
      after(() => {
        if (
          name === "setAuthenticated" &&
          WebSocketService.isAuthenticated() &&
          WebSocketService.isConnected()
        ) {
          AccountService.webSocketInitialized();
        } else if (name === "onReconnectError") {
          if (useDsSystemNotifierStore().webSocketNotificationId === 0) {
            //Send notification
            const id = DsNotifier.sendInfo({
              duration: 0,
              enableClose: false,
              position: "top-0 right-0",
              overlay: true,
              component: markRaw({
                name: WebSocketReconnectionErrorToast,
              }),
            });
            useDsSystemNotifierStore().setWebSocketNotificationId(id);
          }
        }
      });
    }
  );

  startPresencePolling();
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      startPresencePolling();
    } else {
      window.clearInterval(intervalId);
    }
  });
});

onUnmounted(() => {
  unsubscribePresences();
  unsubscribeSocketAuthenticated();
  window.clearInterval(intervalId);
});

async function pollPresence(): Promise<void> {
  const presenceIds = useChatStore().presences.map((presence) => presence.id);
  if (presenceIds.length > 0) {
    /**
     * For any weird reason this endpoint has ERR_NETWORK
     * So, we wrap into try/catch.
     * We don't need  to do anything in the catch
     */
    try {
      const responsePresences = await PresenceService.poll(presenceIds);
      const newPresences = responsePresences.map((creator): Presence => {
        return {
          id: creator.creator_id,
          presence: creator.presence ? "active" : "inactive",
        };
      });
      useChatStore().setPresences(newPresences);
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }
    }
  }
}

function startPresencePolling(): void {
  intervalId = window.setInterval(() => {
    pollPresence();
  }, 60000);
}

watch(
  () => route,
  (to) => {
    if (to.meta.layout !== undefined) {
      layout.value = Reflect.get(layouts, String(to.meta.layout));
    } else {
      layout.value = AuthenticatedLayout;
    }
  },
  {
    deep: true,
  }
);
</script>
