import { SubscriptionStatus } from "@/modules/settings/enums/SubscriptionStatusEnum";
import { BenefitStatus } from "@/modules/settings/enums/BenefitStatusEnum";
import type { Subscription } from "@/modules/settings/services/subscription/SubscriptionService.types";

export default class SubscriptionHandler {
  static enabledWhiteLabel(subscription: Subscription): boolean {
    return subscription?.plan_benefits.white_label === BenefitStatus.Enabled;
  }

  static hasInvalidSubscription(subscription: Subscription): boolean {
    return (
      !subscription ||
      subscription.status === SubscriptionStatus.Draft ||
      (subscription.current_plan.plan_id === 0 &&
        subscription.status === SubscriptionStatus.Canceled)
    );
  }

  static massInviteEnabled(subscription: Subscription): boolean {
    return (
      subscription?.plan_benefits.mass_invite_creators === BenefitStatus.Enabled
    );
  }
}
