import { ApiService } from "@/core/shared/services/ApiService";
import type {
  StripeLink,
  Subscription,
  SubscriptionPrice,
  SubscriptionUpdated,
} from "@/modules/settings/services/subscription/SubscriptionService.types";

export default class SubscriptionService {
  /**
   * Create a subscription link to stripe
   * @endpoint /company/stripe-subscriptions/create-link
   * @httpMethod POST
   */
  static async createLink(
    price_lookup_key: string,
    success_url: string,
    cancel_url: string
  ): Promise<StripeLink> {
    return ApiService.post("/company/stripe-subscriptions/create-link", {
      price_lookup_key,
      success_url,
      cancel_url,
    });
  }

  /**
   * Get subscription info
   * @endpoint /company/stripe-subscriptions/subscription-data
   * @httpMethod GET
   */
  static async subscription(): Promise<Subscription> {
    return ApiService.get("/company/stripe-subscriptions/subscription-data");
  }

  /**
   * Cancel subscription
   * @endpoint /company/stripe-subscriptions/cancel
   * @httpMethod PUT
   */
  static async cancel(
    cancellation_reason: string | null
  ): Promise<SubscriptionUpdated> {
    return ApiService.put("/company/stripe-subscriptions/cancel", {
      cancellation_reason,
    });
  }

  /**
   * Upgrades or downgrades a subscription
   * @endpoint /company/stripe-subscriptions/update
   * @httpMethod PUT
   */
  static async update(price_lookup_key: string): Promise<SubscriptionUpdated> {
    return ApiService.put("/company/stripe-subscriptions/update", {
      price_lookup_key,
    });
  }

  /**
   * Revoke subscription cancellation
   * @endpoint /company/stripe-subscriptions/revoke-cancellation
   * @httpMethod PUT
   */
  static async revokeCancellation(): Promise<SubscriptionUpdated> {
    return ApiService.put("/company/stripe-subscriptions/revoke-cancellation");
  }

  /**
   * Get prices info
   * @endpoint /company/stripe-subscriptions/list-prices
   * @httpMethod GET
   */
  static async prices(): Promise<SubscriptionPrice[]> {
    return ApiService.get("/company/stripe-subscriptions/list-prices");
  }

  /**
   * Update payment method
   * @endpoint /company/stripe-subscriptions/payment-method-update-link
   * @httpMethod POST
   */
  static async updatePayment(
    success_url: string,
    cancel_url: string
  ): Promise<StripeLink> {
    return ApiService.post(
      "/company/stripe-subscriptions/payment-method-update-link",
      {
        success_url,
        cancel_url,
      }
    );
  }

  /**
   * Synchronize subscription data
   * @endpoint /company/stripe-subscriptions/synchronize-data
   * @httpMethod GET
   */
  static async synchronizeData(): Promise<Subscription> {
    return ApiService.get("/company/stripe-subscriptions/synchronize-data");
  }

  /**
   * Synchronize subscription data
   * @endpoint /company/stripe-subscriptions/synchronize-data
   * @httpMethod GET
   */
  static async synchronizeSession(): Promise<Subscription> {
    return ApiService.get(
      "/company/stripe-subscriptions/synchronize-session-data"
    );
  }
}
