import {
  DsNotifier,
  useDsSystemNotifierStore,
  useApiStore,
} from "@devsalsa/vue-core";
import { ApiErrorTranslator } from "@/core/shared/helpers/Error/ApiErrorTranslator";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

export class MaintenanceHandler {
  static setOn(error: ApiServiceError) {
    useApiStore().setMaintenanceOn();
    if (useDsSystemNotifierStore().maintenanceNotificationId === 0) {
      const id = DsNotifier.sendInfo({
        message: ApiErrorTranslator.translate(error),
        duration: 0,
        enableClose: false,
        position: "top-0 right-0",
        overlay: true,
      });
      useDsSystemNotifierStore().setMaintenanceId(id);
    }
  }
  static setOff() {
    useApiStore().setMaintenanceOff();
    DsNotifier.delete(useDsSystemNotifierStore().maintenanceNotificationId);
    useDsSystemNotifierStore().setMaintenanceId(0);
  }
}
