import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import WhiteLabelingService from "@/modules/settings/services/white-labeling/WhiteLabelingService";
import SubscriptionHandler from "@/shared/helpers/SubscriptionHandler";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import type { Hostname } from "@/modules/settings/services/white-labeling/WhiteLabelingService.types";
import { DsFlashNotifier } from "@devsalsa/vue-core";
import i18n from "@/i18n";

const isWhiteLabelEnabled = async () => {
  const accountInfo = await AccountHandler.getInfo();
  return SubscriptionHandler.enabledWhiteLabel(accountInfo.stripe_subscription);
};

export default class CustomDomainGuard {
  static async getHostnameList(to: RouteLocationNormalized) {
    to.meta.hostnames = await WhiteLabelingService.getHostnameList();
  }

  static withWhiteLabelEnabled = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    if (await isWhiteLabelEnabled()) {
      return next();
    }
    return next({ name: "WhiteLabeling" });
  };

  static withoutDomain = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    const hostNames = await WhiteLabelingService.getHostnameList();
    if (hostNames.length === 0) {
      return next();
    }
    return next({ name: "WhiteLabeling" });
  };

  static withDomain = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    const hostnames = await WhiteLabelingService.getHostnameList();
    const hostnameId = Number(to.params.hostnameId);
    const hostname = hostnames.find(
      (hostname: Hostname) => hostname.id === hostnameId
    ) as Hostname;
    if (hostname) {
      to.meta.hostname = hostname;
      return next();
    }

    DsFlashNotifier.error(
      i18n.global.t(
        "modules.settings.whiteLabeling.setupDomain.hostnameNotFound"
      )
    );
    return next({ name: "WhiteLabeling" });
  };
}
