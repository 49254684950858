import { UgcTypeName } from "@/shared/enums/UgcTypeEnum";
import CampaignCreationLocalData from "@/modules/campaign/creation/helpers/CampaignCreationLocalData";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import { DsFlashNotifier } from "@devsalsa/vue-core";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import ProductService from "@/modules/product/services/ProductService";

export default class CampaignCreationStepValidator {
  static basics() {
    const { ugc_type, certification_id } = CampaignCreationLocalData.init.value;
    if (!(ugc_type && certification_id)) {
      return false;
    }

    const { title, brand_id } = CampaignCreationLocalData.basics.value;
    if (!(title && brand_id)) {
      return false;
    }

    return [
      UgcTypeName.Image,
      UgcTypeName.Video,
      UgcTypeName.Bundle,
      UgcTypeName.Instagram,
      UgcTypeName.TikTok,
      UgcTypeName.YouTube,
      UgcTypeName.Amazon,
      UgcTypeName.TikTokShop,
    ].includes(ugc_type);
  }

  static async settings() {
    const { private_campaign, certification_id } =
      CampaignCreationLocalData.init.value;
    const { product } = CampaignCreationLocalData.basics.value;
    const { director_notes } = CampaignCreationLocalData.settings.value;
    const { certifications } = CampaignCreationLocalData.creator.value;

    if (!(product.id && director_notes)) {
      return false;
    }

    try {
      await ProductService.get(product.id);
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }

      DsFlashNotifier.error(ErrorTranslator.translate(error));
      CampaignCreationLocalData.clear();
      return false;
    }

    if (
      private_campaign === 1 &&
      certifications.find(
        (certification) => certification.job_type_id === certification_id
      ) === undefined
    ) {
      const certificate = certifications.find(
        (item) => item.job_type_id === certification_id
      );
      if (certificate !== undefined) {
        CampaignCreationLocalData.init.value.certification_id =
          certificate.job_type_id;
        return true;
      }

      return false;
    }

    return true;
  }

  static summary() {
    const { ugc_type } = CampaignCreationLocalData.init.value;
    if (!CampaignCreationLocalData.basics.value) {
      return "CampaignCreationBasics";
    }

    const { product, title } = CampaignCreationLocalData.basics.value;
    if (!product.id || !title) {
      return "CampaignCreationBasics";
    }

    if (!CampaignCreationLocalData.settings.value) {
      return "CampaignCreationsSettings";
    }

    if (ugc_type !== UgcTypeName.Bundle) {
      if (!CampaignCreationLocalData.creators.value) {
        return "CampaignCreationCreators";
      }
    }
    return "";
  }

  static isBundle() {
    const { ugc_type } = CampaignCreationLocalData.init.value;
    return ugc_type === UgcTypeName.Bundle;
  }
}
