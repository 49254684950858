import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "/products",
        name: "Products",
        component: () => import("@/modules/product/views/TheProducts.vue"),
        meta: {
          title: "modules.product.products.meta.title",
          scope: ScopeType.Products,
        },
      },
      {
        path: "/product/:productId(\\d+)",
        name: "Product",
        component: () => import("@/modules/product/views/TheProduct.vue"),
        meta: {
          title: "modules.product.products.meta.title",
          scope: ScopeType.Products,
        },
      },
    ],
  },
];
