import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";
import CampaignCreationGuard from "@/modules/campaign/creation/helpers/CampaignCreationGuard";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign/:campaignId(\\d+)",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: ":action?",
        name: "CampaignSummary",
        component: () =>
          import("@/modules/campaign/information/views/CampaignSummary.vue"),
        beforeEnter: CampaignCreationGuard.withResubmit,
        meta: {
          title: "modules.campaign.productInformation.meta",
          scope: ScopeType.Campaigns,
        },
      },
      {
        path: "update-payment-method",
        name: "UpdateCampaignPaymentMethod",
        component: () =>
          import(
            "@/modules/campaign/information/views/UpdateCampaignPaymentMethod.vue"
          ),
        meta: {
          title: "modules.campaign.productInformation.meta",
          scope: ScopeType.PaymentMethods,
        },
      },
    ],
  },
];
