import i18n from "@/i18n";
import type { ApiServiceErrorValidationRule } from "@/core/shared/services/Error/ApiServiceError";

export class ApiErrorValidationTranslator {
  static translate(
    ruleName: string,
    rule: ApiServiceErrorValidationRule
  ): string {
    if (ruleName === "max_string_chars") {
      return i18n.global.t("common.error.validation.maxStringChars", rule.data);
    }
    if (ruleName === "file_too_large") {
      return i18n.global.t("common.error.validation.fileTooLarge", {
        maxSize: rule.data.max_size,
      });
    }
    if (ruleName === "image_insufficient_resolution") {
      const data = rule.data;
      return i18n.global.t(
        "common.error.validation.imageInsufficientResolution",
        {
          requiredWidth: data.required[0],
          requiredHeight: data.required[1],
          detectedWidth: data.detected[0],
          detectedHeight: data.detected[1],
        }
      );
    }
    if (ruleName === "video_insufficient_resolution") {
      const data = rule.data;
      return i18n.global.t(
        "common.error.validation.videoInsufficientResolution",
        {
          requiredWidth: data.required[0],
          requiredHeight: data.required[1],
          detectedWidth: data.detected[0],
          detectedHeight: data.detected[1],
        }
      );
    }
    if (ruleName === "not_duration_required") {
      const data = rule.data;
      return i18n.global.t("common.error.validation.notDurationRequired", {
        min: data.min,
        max: data.max,
      });
    }
    if (ruleName === "billing_no_invoices") {
      return i18n.global.t("common.error.validation.billingNoInvoices");
    }
    if (ruleName === "already_has_rating") {
      return i18n.global.t("common.error.validation.alreadyHasRating");
    }

    return rule.message;
  }
}
