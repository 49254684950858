import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import CampaignService from "@/modules/campaign/services/CampaignService";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import { CampaignPricingModel } from "@/modules/campaign/enums/CampaignPricingModelEnum";

export default class MarketplaceGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withCampaign(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const campaignId = Number(to.params.campaignId);
    try {
      const campaign = await CampaignService.get(campaignId);

      /*
        The marketplace should not be available when all jobs of the campaign
        are taken, because for Bundle campaigns is not available add more jobs
      */
      if (
        campaign.auto_approve_creator ||
        (campaign.pricing_model === CampaignPricingModel.Bundle &&
          campaign.available_jobs === 0) ||
        campaign.is_private
      ) {
        return next({
          name: "Dashboard",
        });
      }

      to.meta.campaign = campaign;
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }

      return next({
        name: "Dashboard",
      });
    }
    next();
  }
}
