import type { Directive, DirectiveBinding } from "vue";
import type { CollaboratorPermission } from "@/modules/settings/services/collaborators/CollaboratorsService.types";
import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

const collaboratorPermission: Directive<HTMLElement, CollaboratorPermission[]> =
  {
    mounted(
      el: HTMLElement,
      binding: DirectiveBinding<CollaboratorPermission[]>
    ) {
      const { arg, value } = binding;
      const permissions: CollaboratorPermission[] = value;

      const { is_collaborator, collaborator } = AccountHandler.accountInfo
        .value as AccountInfo;

      if (!is_collaborator) {
        return; // If the user is not a collaborator, simply return without doing anything
      }

      const permissionList = [] as boolean[];
      for (const permission of permissions) {
        const collaboratorPermission = Reflect.get(
          collaborator.scope_permissions,
          permission.scope
        );
        permissionList.push(permission.type.includes(collaboratorPermission));
      }

      let hasPermission;
      if (arg === "or" || !arg) {
        hasPermission = permissionList.some((permission) => permission);
      } else if (arg === "and") {
        hasPermission = permissionList.every((permission) => permission);
      }

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    },
  };

export default collaboratorPermission;
