import {
  DsNotifier,
  useDsSystemNotifierStore,
  useApiStore,
} from "@devsalsa/vue-core";
import i18n from "@/i18n";

export class ConnectivityHandler {
  static setOffline(message?: string): void {
    useApiStore().setOffline();
    if (useDsSystemNotifierStore().connectivityNotificationId === 0) {
      //Send notification
      const id = DsNotifier.sendWarning({
        message:
          message || i18n.global.t("common.error.global.connectivityError"),
        duration: 0,
        enableClose: false,
        position: "top-0 right-0",
        overlay: true,
      });
      useDsSystemNotifierStore().setConnectivityId(id);
    }
  }
  static setOnline(): void {
    useApiStore().setOnline();
    DsNotifier.delete(useDsSystemNotifierStore().connectivityNotificationId);
    useDsSystemNotifierStore().setConnectivityId(0);
  }
  static isOnline(): boolean {
    return useApiStore().online;
  }
}
