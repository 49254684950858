export enum UgcTypeName {
  Image = "image",
  Video = "video",
  TikTok = "tiktok",
  Bundle = "bundle",
  Social = "social",
  Instagram = "instagram",
  YouTube = "youtube",
  Amazon = "amazon",
  TikTokInstagram = "tiktok_instagram",
  TikTokShop = "tiktok_shop",
}
