<template>
  <router-link
    v-if="link && to"
    :to="to"
    :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    class="nav-link"
    :data-testid="dataTestid"
  >
    <slot name="navbar-item"></slot>
    <component
      v-if="icon"
      :is="icon"
      :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    />
    <span v-else>
      {{ text }}
    </span>
    <slot name="navbar-item-suffix"></slot>
  </router-link>
  <button
    v-else
    class="nav-link"
    :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    :data-testid="dataTestid"
  >
    <slot name="navbar-item"></slot>
    <Component
      v-if="icon"
      :is="icon"
      :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    />
    <span v-else>
      {{ text }}
    </span>
    <slot name="navbar-item-suffix"></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsBellSolidIcon, DsChatBubbleIcon } from "@devsalsa/vue-core";

export default defineComponent({
  name: "NavbarItem",
  components: {
    DsChatBubbleIcon,
    DsBellSolidIcon,
  },
  props: {
    link: {
      type: Boolean,
      required: false,
      default: true,
    },
    to: {
      type: Object,
      required: false,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    text: {
      type: String,
      required: false,
    },
    dataTestid: {
      type: String,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  @apply no-underline
  transition ease-in-out duration-200
  cursor-pointer font-medium text-sm
  hover:text-gray-900;
}
</style>
