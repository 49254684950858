import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { MarketplaceHandler } from "@/core/shared/helpers/MarketplaceHandler";
import CompanyService from "@/shared/services/CompanyService";
import AssetsService from "@/shared/services/AssetsService";

export default class TikTokAdCenterGuard {
  static async withStats(to: RouteLocationNormalized) {
    to.meta.companyStats = await CompanyService.stats();
    to.meta.tiktokAdEnums = await AssetsService.tiktokAdEnums();
    to.meta.brands = await CompanyService.getBrands();
  }

  static async withMarketplaceAvailable(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    const currentMarketplace = MarketplaceHandler.getCurrent();
    const marketplaceAvailable = to.meta.marketplaceAvailable;

    if (currentMarketplace !== marketplaceAvailable) {
      return next({
        name: "Dashboard",
      });
    }

    next();
  }
}
