import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import RecurringMarketplaceGuard from "@/modules/campaign/recurring-marketplace/helpers/RecurringMarketplaceGuard";
import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign/recurring/:campaignId(\\d+)",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      RecurringMarketplaceGuard.withRecurringCampaign,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "marketplace",
        name: "RecurringCampaignMarketplace",
        component: () =>
          import(
            "@/modules/campaign/recurring-marketplace/views/RecurringCampaignMarketplace.vue"
          ),
        meta: {
          title: "modules.campaign.marketplace.recurring.meta",
          scope: ScopeType.RecurringCampaigns,
          permission: PermissionType.Full,
        },
      },
    ],
  },
];
