import type {
  AccountInfo,
  AccountToken,
  AccountTwoFactorAuthenticationCodeSent,
  AccountUpdated,
  ContactResponse,
  Phone,
  PhoneUpdated,
  ProfileInformationFormData,
  VerificationMethods,
} from "@/core/modules/account/services/AccountService.types";
import { ApiService } from "@/core/shared/services/ApiService";
import type { RouteParamValue } from "vue-router";
import { EmailVerificationType } from "@/modules/settings/enums/EmailVerificationTypeEnum";

/**
 * @class AccountService
 */
export default class AccountService {
  /**
   * Register Company Account
   * @throws {ApiServiceError}
   * @returns {Promise<AccountToken>}
   * @param data
   */
  static register(data: FormData): Promise<AccountToken> {
    return ApiService.post("/company/companies", data);
  }

  /**
   * Get Information of Account
   * @throws {ApiServiceError}
   * @return {Promise<AccountInfo>}
   */
  static getInfo(): Promise<AccountInfo> {
    return ApiService.get("/company/companies");
  }

  /**
   * Get stats
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static webSocketInitialized(): Promise<AccountUpdated> {
    return ApiService.get(`/company/companies/websocket-initialized`);
  }

  /**
   * Login account
   * @param email {string} Email
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   */
  static login(email: string, password: string): Promise<AccountToken> {
    return ApiService.post("/company/companies/login", {
      email: email,
      password: password,
    });
  }

  /**
   * Two-factor authenticate the account
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param code
   */
  static authenticateTwoFactor(code: string): Promise<AccountUpdated> {
    return ApiService.post("/company/login/2fa/verify", {
      code: code,
    });
  }

  /**
   * Resend Email Verification
   * @throws {ApiServiceError}
   * @return {Promise<AccountTwoFactorAuthenticationCodeSent>}
   */
  static resendTwoFactorAuthenticationCode(
    type: string,
    phoneId = 0
  ): Promise<AccountTwoFactorAuthenticationCodeSent> {
    return ApiService.post("/company/login/2fa/send", {
      type: type,
      phone_id: phoneId,
    });
  }

  /**
   * Login with Facebook
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithFacebook(data: FormData): Promise<AccountToken> {
    return ApiService.post("/company/companies/facebook-connect", data);
  }

  /**
   * Login with Google
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithGoogle(data: FormData): Promise<AccountToken> {
    return ApiService.post("/company/companies/google-login", data);
  }

  /**
   * Login with Apple
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithApple(data: FormData): Promise<AccountToken> {
    return ApiService.post("/company/companies/apple-connect", data);
  }

  /**
   * Logout Account
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static logout(): Promise<AccountUpdated> {
    return ApiService.delete("/company/companies/logout");
  }

  /**
   * Check Email Verification
   * @param code {string} Code
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static checkEmailVerification(
    code: string | RouteParamValue[]
  ): Promise<AccountUpdated> {
    return ApiService.put(
      `/company/companies/emails/email-verification/${code}`
    );
  }

  /**
   * Resend Email Verification
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resendEmailVerification(
    type = EmailVerificationType.Signup
  ): Promise<AccountUpdated> {
    return ApiService.post("/company/companies/email-verification/resend", {
      type: type,
    });
  }

  /**
   * Set Email
   * @param email {String} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static setEmail(email: string): Promise<AccountUpdated> {
    return ApiService.put("/company/companies/emails", {
      email: email,
    });
  }

  /**
   * Update Email
   * @param email {string} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static updateEmail(email: string): Promise<AccountUpdated> {
    return ApiService.post("/company/companies/change-email", {
      email: email,
    });
  }

  /**
   * Request Password Reset
   * @param email {string} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static requestPasswordReset(email: string): Promise<AccountUpdated> {
    return ApiService.post("/company/companies/lost-password", {
      email: email,
    });
  }

  /**
   * Reset Password
   * @param code {string} Code
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resetPassword(
    code: string,
    password: string
  ): Promise<AccountUpdated> {
    return ApiService.put("/company/companies/lost-password", {
      code: code,
      password: password,
    });
  }

  /**
   * Set Paypal Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static setPaypalEmail(paypal_email: string): Promise<AccountUpdated> {
    return ApiService.put(`/company/companies/paypal-email`, {
      paypal_email,
    });
  }

  /**
   * Updated Password
   * @param oldPassword {string} Old password
   * @param newPassword {string} New password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<AccountUpdated> {
    return ApiService.put("/company/companies/password", {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }

  /**
   * Updated Profile information
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   * @param data
   */
  static update(data: ProfileInformationFormData): Promise<AccountUpdated> {
    return ApiService.put("/company/companies", data);
  }

  /**
   * Delete brand account
   */
  static deleteAccount(): Promise<AccountUpdated> {
    return ApiService.delete("/company/companies");
  }

  /**
   * Send message to support
   * @param name {string} Name
   * @param email {string} Email
   * @param message {string} Message
   * @throws {ApiServiceError}
   * @return {Promise<ContactResponse>}
   */
  static contact(
    name: string,
    email: string,
    message: string
  ): Promise<ContactResponse> {
    return ApiService.post(`/common/company-contact-support`, {
      name,
      email,
      message,
    });
  }

  static verificationMethods(): Promise<VerificationMethods> {
    return ApiService.get("/company/login/verification-methods");
  }

  static twoFactorAuthenticationMethods(): Promise<VerificationMethods> {
    return ApiService.get("/company/2fa/verification-methods");
  }

  static addPhone(phone: string): Promise<Phone> {
    return ApiService.post("/company/2fa/phone-numbers/create", {
      phone_number: phone,
    });
  }

  static verifyPhone(phone: string, code: string): Promise<Phone> {
    return ApiService.put("/company/2fa/phone-numbers/verify", {
      phone_number: phone,
      code: code,
    });
  }

  static deletePhone(phone: string): Promise<PhoneUpdated> {
    return ApiService.delete("/company/2fa/phone-numbers/delete", {
      phone_number: phone,
    });
  }

  static checkOldEmail(): Promise<PhoneUpdated> {
    return ApiService.get("/company/companies/check-old-email");
  }

  static setPrimary(phoneId: number): Promise<PhoneUpdated> {
    return ApiService.put("/company/2fa/phone-numbers/set-primary", {
      phone_id: phoneId,
    });
  }

  /**
   * Changes the company default marketplace
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   * @param marketplaceId
   */
  static setDefaultMarketplace(marketplaceId: number): Promise<AccountUpdated> {
    return ApiService.put(`/company/update-default-marketplace`, {
      default_marketplace_id: marketplaceId,
    });
  }
}
