import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import CampaignService from "@/modules/campaign/services/CampaignService";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import CampaignCreationLocalData from "@/modules/campaign/creation/helpers/CampaignCreationLocalData";
import { CampaignStatus } from "@/modules/campaign/enums/CampaignStatusEnum";

export default class CampaignCreationGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withResubmit(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const campaignId = Number(to.params.campaignId);
    try {
      const campaign = await CampaignService.get(campaignId);
      to.meta.campaign = campaign;

      if (
        to.params.action === "resubmit" &&
        campaign.status === CampaignStatus.Rejected
      ) {
        CampaignCreationLocalData.clear();
        Reflect.set(campaign, "product_id", campaign.original_product_id);
        Reflect.set(campaign, "rejected_campaign_id", campaign.id);
        CampaignCreationLocalData.initWithCampaign(campaign);
        return next({
          name: "CampaignCreationBasics",
        });
      }
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }

      return next({
        name: "Dashboard",
      });
    }

    next();
  }
}
