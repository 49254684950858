import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import TikTokBoostStepValidator from "@/modules/tiktok-boost/helpers/step/TikTokBoostStepValidator";
import BoostCreationLocalData from "@/modules/tiktok-boost/helpers/BoostCreationLocalData";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { KycStatus } from "@/core/modules/account/services/AccountService.types";
import CompanyService from "@/shared/services/CompanyService";

export default class TikTokBoostStepGuard {
  /**
   *
   * @param to
   * @param from
   * @param next
   */
  static async creation(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const accountInfo = await AccountHandler.getInfo();
    if (accountInfo.kyc_status !== KycStatus.Approved) {
      const companyStats = await CompanyService.stats();
      if (companyStats.nr_purchased_content.nr_posted_tiktok_videos === 0) {
        return next({
          name: "AdCenter",
        });
      }

      return next();
    }

    return next();
  }

  static async schedule(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidContent = await TikTokBoostStepValidator.details();
    if (!isValidContent) {
      return next({
        name: BoostCreationLocalData.setPosition("BoostDetails", true),
      });
    }

    return next();
  }

  static async audience(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidContent = await TikTokBoostStepValidator.details();
    if (!isValidContent) {
      return next({
        name: BoostCreationLocalData.setPosition("BoostDetails", true),
      });
    }

    const isValidSchedule = TikTokBoostStepValidator.schedule();
    if (!isValidSchedule) {
      return next({
        name: BoostCreationLocalData.setPosition("BoostSchedule", true),
      });
    }

    return next();
  }

  static async payment(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const isValidPayment = TikTokBoostStepValidator.payment();
    if (isValidPayment) {
      return next({
        name: BoostCreationLocalData.setPosition(isValidPayment, true),
      });
    }

    return next();
  }
}
